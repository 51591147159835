import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const state = {
  user: {
    id: null,
    phone: null,
  },
  isClickAuth: false,
  firstLoad: true,
  darkTheme: false,
  loader: false,
  closeApp: false,
  notifications: [],
  api: {
    url: '',
  }
}

const actions = {
  setFirstLoad({ commit }, state) {
    commit('SET_FIRST_LOAD', state)
  },
  setLoader({ commit }, stateLoader) {
    commit('SET_LOADER', stateLoader)
  },
  setUser({ commit }, stateUser) {
    commit('SET_USER', stateUser)
  },
  setDarkTheme({ commit }, stateTheme) {
    commit('SET_DARK_THEME', stateTheme)
  },
  setApi({ commit }, stateUrl) {
    commit('SET_API', stateUrl)
  },
  setNotifiction({ commit }, {state, text, timeout = 3000}) {
    const id = Number(new Date())
    commit('SET_NOTIFICATION', { state: state, text: text, id: id})
    setTimeout(() => {
      commit('DELETE_NOTIFICATION', id)
    }, timeout);
  },
  closeApp({ commit }) {
    commit('CLOSE_APP')
  },
  setClicks({ commit }, action){
    axios({
      method: "post",
      url: state.api.url + '/action',
      data: {
        action: action,
        user_id: state.user.id
      }
    })
        .then(response => {
          console.log(response)
          commit('SET_CLICKS', action)
        })
        .catch(error => {
          console.log(error)
        })
  }
}

const getters = {
}

const mutations = {
  SET_LOADER(state, stateLoader) {
    state.loader = stateLoader
  },
  SET_FIRST_LOAD(state, stateLoad) {
    state.firstLoad = stateLoad
  },
  CLOSE_APP(state) {
    state.closeApp = true
  },
  SET_API(state, url) {
    state.api.url = url
  },
  SET_USER(state, stateUser) {
    state.user = stateUser
  },
  SET_DARK_THEME(state, stateTheme) {
    state.darkTheme = stateTheme
  },
  SET_NOTIFICATION(state, notification) {
    state.notifications.push(notification)
  },
  DELETE_NOTIFICATION(state, notificationId) {
    state.notifications.forEach((notification, index) => {
      if(notification.id === notificationId) {
        state.notifications.splice(index, 1)
      }
    })
  },
  SET_CLICKS(state, action){
    if(action === 'auth'){
      state.isClickAuth = true
    }
    if(action === 'reg'){
      state.isClickReg = true
    }
    if(action === 'receipt_input'){
      state.isClickInput = true
    }
    if(action === 'receipt_scan'){
      state.isClickScan = true
    }
    if(action === 'receipt_qr'){
      state.isClickQr = true
    }
    if(action === 'receipt_download'){
      state.isClickReceipt = true
    }
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
