import { render, staticRenderFns } from "./CodeSlide.vue?vue&type=template&id=33d04766&scoped=true&"
import script from "./CodeSlide.vue?vue&type=script&lang=js&"
export * from "./CodeSlide.vue?vue&type=script&lang=js&"
import style0 from "./CodeSlide.vue?vue&type=style&index=0&id=33d04766&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d04766",
  null
  
)

export default component.exports