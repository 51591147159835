<template>
	<div>
		<label
			v-if="label.length"
			class="label"
		>
			{{ label }}
		</label>
		<DatePicker
			:class="error && 'error-datepicker'"
			v-model="inputValue"
			:format="format"
			:placeholder="placeholder"
			:lang="ru"
			:type="type"
		></DatePicker>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ru from 'vue2-datepicker/locale/ru';

export default {
	name: 'InputComponent',
	props: {
		label: {type: String, default: ''},
		placeholder: {type: String, default: ''},
		error: {type: Boolean, default: false},
		type: {type: String, default: 'date'},
	},
	components: {
		DatePicker
	},
	data() {
		return {
			ru: ru,
			inputValue: '',
		}
	},
	computed: {
		format() {
			return this.type === 'time' ? 'HH:mm' : 'DD.MM.YYYY'
		}
	},
	watch: {
		inputValue() {
			this.$emit('input', this.inputValue)
		}
	}
}
</script>

<style lang="scss" scoped>
	.input {
		border: 1px solid #D9D9D9;
		border-radius: 5px;
		padding: 0 12px;
		height: 40px;
		font-family: 'Roboto';
		font-size: 18px;
		line-height: 18px;
		width: 100%;
		box-sizing: border-box;
		outline: none;
	}
	.label {
		font-weight: 400;
		font-size: 19px;
		line-height: 23px;
	}
</style>

<style lang="scss">
	.mx-datepicker {
		width: 100%;
	}
	.mx-input {
		border: 1px solid #D9D9D9;
		border-radius: 5px;
		padding: 0 12px;
		height: 40px;
		font-family: 'Roboto';
		font-size: 18px;
		line-height: 18px;
		width: 100%;
		box-sizing: border-box;
		outline: none;

		&:hover {
			border-color: #D9D9D9;
		}
	}
	.error-datepicker {
		.mx-input {
			border-color: #EB6161;
		}
	}
</style>
