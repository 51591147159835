<template>
	<div class="slide-phone">
		<InputPhone
			@input="phone = $event"
		/>
		<Button
			:disabled="typePage === 'register' ? (!accessPolitic || !phone) : !phone"
			text="Получить код по смс"
			@click="checkPhone()"
		/>
	</div>
</template>

<script>
import InputPhone from '@/components/InputPhone'
import Button from '@/components/ButtonComponent'
import axios from 'axios'

export default {
	name: 'PhoneSlide',
	components: {
		InputPhone, Button
	},
	props: {
		typePage: {type: String, required: true}
	},
	data() {
		return {
			phone: '',
			accessPolitic: false,
		}
	},
	methods: {
		checkPhone() {
			this.$store.dispatch('setLoader', true)
			axios({
				method: "post",
				url: this.$store.state.api.url + '/phone',
				data: {
					phone: this.phone,
					user_id: this.$store.state.user.id
				}
			})
			.then(response => {
				console.log(response);
				if (response.data.status) {
					this.$store.dispatch('setUser', {
						id: this.$store.state.user.id,
						phone: this.phone,
					})
					this.$emit('nextSlide')
					this.$store.dispatch('setLoader', false)
				} else {
					this.$store.dispatch('setLoader', false)
					this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
				}
			})
			.catch(error => {
				console.log(error);
				this.$store.dispatch('setLoader', false)
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже или завтра'})
			})
		},
	}
}
</script>

<style lang="scss" scoped>
	.slide-phone {
		display: flex;
		flex-direction: column;
		gap: 50px;
	}
	.politic {
		font-weight: 400;
		font-size: 18px;
		line-height: 18px;
		display: grid;
		grid-template-columns: 40px 1fr;

		a {
			color: #5EA7DE;
			text-decoration: none;
			font-weight: 600;
		}
	}
</style>
