<template>
	<button
		class="button"
		:class="[secondary ? 'secondary' : 'primary', disabled && 'disabled', back && 'back']"
		@click="!disabled && $emit('click')"
	>
		{{ text }}
	</button>
</template>

<script>
export default {
	name: 'ButtonComponent',
	props: {
		text: {type:  String, required: true},
		secondary: {type: Boolean, default: false},
		disabled: {type: Boolean, default: false},
    back: {type: Boolean, default: false},
	}

}
</script>

<style lang="scss" scoped>
	.button {
		height: 60px;
		width: 100%;
		border: 1px solid #00ADEE;
		border-radius: 14px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 21px;
		outline: none;
		cursor: pointer;
	}

	.primary {
		background-color: #00ADEE;
		color: white;

		&.disabled {
			background-color: #a4c4dd;
			border-color: #a4c4dd;
			cursor: default;
		}
	}

	.secondary {
		border-radius: 7px;
		height: 32px;
		border: 1px solid #4B87BB;
		color: #4B87BB;
		background-color: white;
		&.disabled {
			background-color: #e9e9e9;
			border-color: #e9e9e9;
			cursor: default;
		}
	}

  .back {
    margin-top: 10px;
  }
</style>
