<template>
	<div>
		<div class="title1">Загрузка чека</div>
		<div class="text">
			Если вы купили линзы или растворы Alcon менее 30 дней назад,
			то вы можете зарегистрировать чек об их покупке, чтобы
			получить до 1400 бонусных баллов.
		</div>
		<div class="text title2">Обратите внимание!</div>
		<div class="text">
			1. На фотографии чека должен четко читаться QR-код
		</div>
		<div class="text">
			2. Файл должен быть в формате JPEG или PNG, размером не
			больше 20MB
		</div>
		<div class="text">
			3. Вы можете отсканировать и\или сфотографировать не более 2
			(двух) чеков в день, и не более 4 (четырех) в месяц.
		</div>
		<div class="buttons">
			<Button
				text="Отсканировать чек"
				secondary
				@click="$emit('openScannerSlide')"
			/>
			<Button
				text="Отправить QR-код чека"
				secondary
				@click="$refs.uploadFile.click()"
			/>
			<Button
				text="Отправить данные из чека"
				secondary
				@click="$emit('openFormSlide')"
			/>
			<input type="file" hidden ref="uploadFile" @change="uploadFile">
		</div>
	</div>
</template>

<script>
import Button from '@/components/ButtonComponent'
import axios from 'axios'

export default {
	name: 'MainSlide',
	components: {
		Button,
	},
	methods: {
		uploadFile(event) {
      // if(!this.$store.state.isClickQr){
      //   this.$store.dispatch('setClicks', 'receipt_qr')
      // }
			this.$store.dispatch('setLoader', true)
			let data = new FormData();
			data.append('file', event.target.files[0])
			data.append('user_id', this.$store.state.user.id)
			data.append('platform', 'tg')
			axios({
				method: "post",
				url: this.$store.state.api.url + '/photo',
				data: data,
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((response) => {
				if (response.data.status) {
					this.$store.dispatch('setLoader', false)
					this.$emit('openSuccessSlide')
				} else if(response.data.message === 'not_file'){
					this.$store.dispatch('setLoader', false)
					this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, файл не подходит. Попробуйте другой'})
				} else {
					this.$store.dispatch('setLoader', false)
					this.$store.dispatch('setNotifiction', { state: 'error', text: response.data.message})
				}
			})
			.catch(error => {
				console.log(error);
				this.$store.dispatch('setLoader', false)
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
				this.$store.dispatch('setNotifiction', { state: 'error', text: error})
			})
		},
	}
}
</script>

<style lang="scss" scoped>
	.title1 {
		font-weight: 600;
		color: #50A7EA;
		font-size: 22px;
		line-height: 18px;
	}
	.title2 {
		font-weight: 600;
	}
	.text {
		margin: 10px 0;
	}
	.buttons {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
</style>
