<template>
	<div class="page page-authorization">
		<div class="page__header">

		</div>
		<div class="page__body">
			<AuthorizationSlide
				v-if="slide === 1"
				:typePage="stateSlide"
				@registration="slide = 2; stateSlide = 'register'"
				@successSlide="slide = 3"
			/>
			<SuccessSlide
				v-if="slide === 3"
				:stateSlide="stateSlide"
			/>
		</div>
	</div>
</template>

<script>
import AuthorizationSlide from './AuthorizationSlide'
import SuccessSlide from './SuccessSlide'

export default {
	name: 'AuthorizationPage',
	components: {
		AuthorizationSlide, SuccessSlide
	},
	data() {
		return {
			stateSlide: 'login',
			slide: 1,
		}
	},
	mounted() {
		if (this.$store.state.user.phone) {
			this.slide = 3
		}
    if(!this.$store.state.isClickAuth){
      this.$store.dispatch('setClicks', 'auth')
    }
	}
}
</script>

<style lang="scss" scoped>
	.page__header {
		margin-top: 20px;
		display: flex;
		gap: 4px;
		font-size: 19px;
		line-height: 18px;
	}
	.header__elem {
		border-bottom: 1px solid black;
		width: calc(50% + 2px);
		text-align: center;
		padding-bottom: 2px;
		cursor: pointer;

		&.select {
			border-bottom: 2px solid #5EA7DE;
		}
	}
	.page__body {
		margin-top: 40px;
		padding: 0 10px;
	}
</style>
