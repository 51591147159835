<template>
	<div>
		<label
			v-if="label.length"
			class="label"
		>
			{{ label }}
		</label>
		<input
			class="input"
			:placeholder="placeholder"
			v-mask="mask"
			:class="error && 'error'"
			v-model="inputValue"
			:type="type"
		>
	</div>
</template>

<script>
export default {
	name: 'InputComponent',
	props: {
		label: {type: String, default: ''},
		mask: {type: String, default: ''},
		placeholder: {type: String, default: ''},
		value: {type: String, default: ''},
		type: {type: String, default: 'text'},
		error: {type: Boolean, default: false},
	},
	data() {
		return {
			inputValue: ''
		}
	},
	mounted() {
		this.inputValue = this.value
	},
	watch: {
		value() {
			this.inputValue = this.value
		},
		inputValue() {
			this.$emit('input', this.inputValue)
		}
	}
}
</script>

<style lang="scss" scoped>
	.input {
		border: 1px solid #D9D9D9;
		border-radius: 5px;
		padding: 0 12px;
		height: 40px;
		font-family: 'Roboto';
		font-size: 18px;
		line-height: 18px;
		width: 100%;
		box-sizing: border-box;
		outline: none;
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}
	}
	.label {
		font-weight: 400;
		font-size: 19px;
		line-height: 23px;
	}
	.error {
		border-color: #EB6161;
	}
</style>
