<template>
	<div class="inputs">
		<div>
			Введите данные из чека, указанные ниже:
		</div>
		<Input
			label="ФН"
			placeholder="0000000000000000"
			mask="################"
			:error="form.fn && form.fn.length !== 16"
			@input="form.fn=$event"
		/>
		<Input
			label="ФД"
			placeholder="0000000000"
			mask="##########"
			:error="form.fd && form.fd.length !== 10"
			@input="form.fd=$event"
		/>
		<Input
			label="ФПД или ФП"
			placeholder="0000000000"
			mask="##########"
			:error="form.fpd && form.fpd.length !== 8 && form.fpd.length !== 10"
			@input="form.fpd=$event"
		/>
		<InputDatepicker
			label="Дата"
			placeholder="01.01.2022"
			:error="error.date"
			@input="form.date=$event"
		/>
		<InputDatepicker
			label="Время"
			placeholder="00:00"
			:error="error.time"
			@input="form.time=$event"
			type="time"
		/>
		<Input
			label="Сумма"
			placeholder="1000.00"
			:error="form.amount && form.amount < 1"
			@input="form.amount=$event"
			type="number"
		/>
		<Button
			@click="upload()"
			text="Загрузить чек"
		/>
		<Button
			@click="$emit('openMainSlide')"
			secondary
			text="Назад"
		/>
	</div>
</template>

<script>
import Button from '@/components/ButtonComponent'
import Input from '@/components/InputComponent'
import InputDatepicker from '@/components/InputDatepicker'
import axios from 'axios'

export default {
	name: 'FormSlide',
	components: {
		Button, Input, InputDatepicker
	},
	data() {
		return {
			form: {
				fn: null,
				fd: null,
				fpd: null,
				date: null,
				time: null,
				amount: null,
			},
			error: {
				date: false,
				time: false
			}
		}
	},
  // mounted() {
  //   if(!this.$store.state.isClickInput){
  //     this.$store.dispatch('setClicks', 'receipt_input')
  //   }
  // },
  methods: {
		upload() {
			if (!this.form.fn || this.form.fn.length !== 16) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректный фн'})
				return
			}
			if (!this.form.fd || this.form.fd.length !== 10) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректный фд'})
				return
			}
			if (!this.form.fpd || (this.form.fpd.length !== 8 && this.form.fpd.length !== 10)) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректный фдп или фп'})
				return
			}
			if (!this.form.date) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректную дату'})
				this.error.date = true
				return
			}
			if (!this.form.time) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректное время'})
				this.error.time = true
				return
			}
			if (!this.form.amount || this.form.amount < 1) {
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'Введите корректную сумму'})
				return
			}
			this.$store.dispatch('setLoader', true)
			let time = new Date(this.form.time).getHours() > 9 ? new Date(this.form.time).getHours() : ('0' + new Date(this.form.time).getHours())
			time += ':'
			time += new Date(this.form.time).getMinutes() > 9 ? new Date(this.form.time).getMinutes() : ('0' + new Date(this.form.time).getMinutes())
			let date = new Date(this.form.date).getDate() > 9 ? new Date(this.form.date).getDate() : ('0' + new Date(this.form.date).getDate())
			date += '/'
			date += new Date(this.form.date).getMonth() > 9 ? new Date(this.form.date).getMonth() : ('0' + new Date(this.form.date).getMonth())
			date += '/'
			date += new Date(this.form.date).getFullYear()
			axios({
				method: "post",
				url: this.$store.state.api.url + '/send_qr',
				data: {
					message : "",
					platform : 'tg',
					receipt : {
						m: "",
						fn: this.form.fn,
						fd: this.form.fd,
						fpd: this.form.fpd,
						dt: date,
						tm: time,
						amount: Number(Number(this.form.amount).toFixed(2)),
						qr: "",
						type: "input"
					},
					user_id : this.$store.state.user.id,
					fbapp: navigator.userAgent.match(/MessengerLiteForiOS/i) ? 1 : 0,
					ua: navigator.userAgent
				}
			})
			.then((response) => {
				if (response.data.status) {
					this.$store.dispatch('setLoader', false)
					this.$emit('openSuccessSlide')
				} else {
					this.$store.dispatch('setLoader', false)
					this.$store.dispatch('setNotifiction', { state: 'error', text: response.data.message})
				}
			})
			.catch(error => {
				console.log(error);
				this.$store.dispatch('setLoader', false)
				this.$store.dispatch('setNotifiction', { state: 'error', text: 'К сожалению, произошла ошибка. Попробуйте позже'})
				this.$store.dispatch('setNotifiction', { state: 'error', text: error})
			})
		}
	},
	watch: {
		form: {
			handler() {
				if (this.form.date) {
					this.error.date = false
				}
				if (this.form.time) {
					this.error.time = false
				}
			},
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>
	.inputs {
		display: flex;
		flex-direction: column;
		gap: 20px;

		@media (max-width: 500px) {
			padding-bottom: 300px;
		}
	}
</style>
