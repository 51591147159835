<template>
	<div>
		<PhoneSlide
			v-if="slide === 1"
			:typePage="typePage"
			@nextSlide="slide++"
		/>
		<CodeSlide
			v-if="slide === 2"
			:typePage="typePage"
      @prevSlide="slide--"
			@registration="$emit('registration')"
			@successSlide="$emit('successSlide')"
		/>
	</div>
</template>

<script>
import PhoneSlide from './PhoneSlide'
import CodeSlide from './CodeSlide'

export default {
	name: 'AuthorizationSlide',
	components: {
		PhoneSlide, CodeSlide
	},
	props: {
		typePage: {type: String, required: true}
	},
	data() {
		return {
			slide: 1,
			phone: '',
		}
	},
}
</script>

