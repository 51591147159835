<template>
	<div>
		<div class="title1">Чек загружен</div>
		<div class="text">
			Спасибо! Ваш чек загружен и отправляется на проверку!
		</div>
		<div class="text title2">Хотите загрузить еще один чек?</div>
		<Button
			text="Загрузить еще чек "
			@click="$emit('openMainSlide')"
		/>
	</div>
</template>

<script>
import Button from '@/components/ButtonComponent'

export default {
	name: 'SuccessSlide',
	components: {
		Button,
	},
}
</script>

<style lang="scss" scoped>
	.title1 {
		font-weight: 600;
		color: #50A7EA;
		font-size: 22px;
		line-height: 18px;
	}
	.title2 {
		font-weight: 600;
	}
	.text {
		margin: 10px 0;
	}
</style>
